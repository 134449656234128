import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
//@ts-ignore
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
Vue.use(LottieVuePlayer);
//@ts-ignore
import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)
//@ts-ignore
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
// import 'vue-fullpage.js/dist/style.css'
//@ts-ignore
// import VueFullPage from 'vue-fullpage.js'
// Vue.use(VueFullPage)
Vue.config.productionTip = false
//@ts-ignore
import Carousel3d from 'vue-carousel-3d';
 
Vue.use(Carousel3d);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
