
import Vue from "vue";
import Component from "vue-class-component";
import HomeMenu from "@/components/HomeMenu.vue";

@Component({
  components: {
    HomeMenu,
  },
})
export default class Main extends Vue {
  contractAddress = "0xd7B543a315e8Bfa2aA0bBBa04Cd923EE98D28aA8"

  menu = {
    drawer: false,
    items: [
      { text: "Home", link: "#home" },
      { text: "About", link: "#about" },
      { text: "Charity", link: "#charity" },
      { text: "Game", link: "#game" },
      { text: "Our Goals", link: "#og" },
      { text: "Score", link: "#score" },
      { text: "Trailer", link: "#trailer" },
      { text: "Token", link: "#token" },
      { text: "NFT", link: "#nft" },
      { text: "Roadmap", link: "#roadmap" },
      { text: "Team", link: "#team" },
      { text: "Partners", link: "#partners" },
      { text: "Contacts", link: "#contacts" },
    ],
  };
  home = {
    background: `url(${require("@/assets/homebg.png")})`,
    logo: JSON.stringify(require("@/assets/animations/Logo.json")),
    birds: JSON.stringify(require("@/assets/animations/birds.json")),
    butterflies: JSON.stringify(
      require("@/assets/animations/HomeButterflies2160.json")
    ),
    girl: JSON.stringify(
      require("@/assets/animations/HomeGirlAndWhiteRabbit.json")
    ),
    menu: JSON.stringify(require("@/assets/animations/MobileMenu.json")),
  };

  about = {
    background: `url(${require("@/assets/aboutbg.png")})`,
    totoro: JSON.stringify(
      require("@/assets/animations/AboutTotoroFlying2160.json")
    ),
    together: JSON.stringify(
      require("@/assets/animations/AboutAllTogether.json")
    ),
  };

  game = {
    background: `url(${require("@/assets/gamebg.png")})`,
    sign1: require("@/assets/gamesign.png"),
    sign2: require("@/assets/gamesign2.png"),
    screenshots: [
      require("@/assets/screenshot (1).png"),
      require("@/assets/screenshot (2).png"),
      require("@/assets/screenshot (3).png"),
    ],
  };

  charity = {
    background: `url(${require("@/assets/charitybg.png")}`,
    data: require("@/assets/charitydata.png"),
    blur: require("@/assets/blur.png"),
  };

  og = {
    background: `url(${require("@/assets/ogbg.png")})`,
    animation: JSON.stringify(
      require("@/assets/animations/OGМальчик+Девочка2160.json")
    ),
    sign: require("@/assets/sign3.png"),
    signShadown: require("@/assets/sign-shadow.png"),
    text: "Тут будет текст",
  };

  team = {
    background: `url(${require("@/assets/teambg.png")})`,
    animation: JSON.stringify(require("@/assets/animations/TeamEyes2160.json")),
    teamTree: require("@/assets/teamtree.png"),
    members: {
      totoro: require("@/assets/team-member5-rounded.png"),
      dmitriy: require("@/assets/team-member2-rounded.png"),
      andrey: require("@/assets/team-member1-rounded.png"),
      kirill: require("@/assets/team-member3-rounded.png"),
      vladimir: require("@/assets/team-member4-rounded.png"),
    },
    dialog: false,
    membersInfo: [
      {
        title: "Main Developer",
        subtitle: "Dan",
        text: "Dan is the head of our contract development department and was involved in numerous multimillion projects (Zelda Inu, Baby Lambo Inu, Totoro Inu) that easily passed CertiK audit getting a high score. He is currently our main deployer & dev.",
      },
      {
        title: "CO-FOUNDER & CEO",
        subtitle: "Demetrius Schaub",
        text: "Demetrius has 5 years of experience in startup marketing. He organizes the marketing of STF, bilding close relationships with KOL, communities and media.",
      },
      {
        title: "CO-FOUNDER & CEO",
        subtitle: "Andreas Antoniou",
        text: "Is responsible for the formation and management of the STF team, Andreas also has extensive experience in the gaming industry. He is responsible for managing and developing the product by the technical team. At the same time, he also has rich experience in decentralized products such as liquidity mining and lending.",
      },
      {
        title: "Mechanical designer and game designer",
        subtitle: "Ki Chen",
        text: "Ki Chen is in charge of all blockchain and game operations, being a gamer at heart. He was an operations manager at the stock exchange. Ki has been working in traditional financial trading for over 3 years.",
      },
      {
        title: "Game designer",
        subtitle: "Voland Solberg",
        text: "Voland Solberg a game designer and also a game producer who is responsible for the design and development of the game. He also has rich experience in P2E games.",
      },
    ],
    memberIndex: 0,
  };

  score = {
    background: `url(${require("@/assets/scorebg.png")})`,
    tableimg: require("@/assets/score-table3.png"),
    animation: JSON.stringify(require("@/assets/animations/ScoreGirl.json")),
  };

  trailer = {
    background: `url(${require("@/assets/trailerbg.png")})`,
    tableimg: require("@/assets/trailer-table.png"),
    susuwatori: require("@/assets/trailer-susuwatari.png"),
    animation: JSON.stringify(require("@/assets/animations/TrailerGirls.json")),
    videoId: "92a7Hj0ijLs",
    playerVars: { autoplay: 1, controls: 0 },
  };

  roadmap = {
    background: `url(${require("@/assets/roadmapbg.png")})`,
    catbusAnimation: JSON.stringify(
      require("@/assets/animations/RoadmapCatBus.json")
    ),
    dialog: false,
    phase: [
      {
        title: "Phase 1",
        items: [
          "Website",
          "Socials",
          "Whitepapper",
          "Contract Deployment",
          "KYC",
          "Audit",
          "Game trailer release",
          "Community 1000",
        ],
      },
      {
        title: "Phase 2",
        items: [
          "Fairlaunch",
          "Pancakeswap Listing",
          "Game release",
          "CMC && GG listings",
          "Charity program start",
          "1000 holders",
        ],
      },
      {
        title: "Phase 3",
        items: [
          "Strategic Partnership Expansion",
          "STF NFT Collection",
          "STF Token Marketplace",
          "Uniswap listing",
          "5000 holders",
        ],
      },
      {
        title: "Phase 4",
        items: [
          "Airdrop program",
          "Game V2 Trailer",
          "Android version",
          "iOS version",
          "10000 holders",
          "CEX listing",
        ],
      },
    ],
    phaseIndex: 0,
  };

  token = {
    background: `url(${require("@/assets/tokenbg.png")})`,
    percentage: require("@/assets/token-percentage.png"),
    stf: require("@/assets/token-stf.png"),
  };

  nft = {
    background: `url(${require("@/assets/nftbg.png")})`,
    soon: require("@/assets/nft-comingsoon.png"),
  };

  contacts = {
    background: `url(${require("@/assets/contactsbg.png")})`,
    colorcor: require("@/assets/contacts-colorcor.png"),
    logo: require("@/assets/homelogo.png"),
    frame: require("@/assets/contacts-frame.png"),
    // text1: require("@/assets/contacts-text1.png"),
    // text2: require("@/assets/contacts-text2.png"),
  };

  partners = {
    background: `url(${require("@/assets/partnersbg.png")})`,
    items: [
      {
        title: "CryptoCat CALLs & AMAs",
        link: "https://t.me/cryptocatcalls",
        src: require("@/assets/partners/1.png"),
      },
      {
        title: "BROTHER CHANNEL",
        link: "https://t.me/BROTHERSMARKETINGCHANNEL",
        src: require("@/assets/partners/2.png"),
      },
      {
        title: "The Solitaire Room",
        link: "https://t.me/TheSolitaireRoom",
        src: require("@/assets/partners/3.png"),
      },
      {
        title: "Whale Coin Talk",
        link: "https://t.me/whalecointalk",
        src: require("@/assets/partners/4.png"),
      },
      {
        title: "InterFi Network Audit",
        link: "https://t.me/InterFiNetwork",
        src: require("@/assets/partners/5.png"),
      },
      // {
      //   title: "PinkSale",
      //   link: "https://t.me/pinkecosystem",
      //   src: require("@/assets/partners/6.png"),
      // },
    ],
  };

  socials = {
    discord: require("@/assets/socials/ds.png"),
    facebook: require("@/assets/socials/fb.png"),
    instagram: require("@/assets/socials/ins.png"),
    telegram: require("@/assets/socials/tg.png"),
    twitter: require("@/assets/socials/tw.png"),
    reddit: require("@/assets/socials/rd.png"),
  };

  get homeMenuSrc() {
    return {
      ...this.socials,
      frame: this.contacts.frame,
      play: require("@/assets/play.png"),
      pause: require("@/assets/pause.png"),
    };
  }

  pickMember(index: number) {
    this.team.memberIndex = index;
    this.team.dialog = true;
  }

  pickPhase(index: number) {
    this.roadmap.phaseIndex = index;
    this.roadmap.dialog = true;
    console.log(`picked phase: ${index + 1}`);
  }

  copyAddress() {
    this.$copyText(this.contractAddress).then(
      function (e) {
        console.log(e);
      },
      function (e) {
        console.log(e);
      }
    );
  }

  drawer = false;
  atAnimation = false;

  toggleDrawer() {
    this.menu.drawer = !this.menu.drawer;
    // const elem = document.getElementById("main-menu");
    // const overlay = document.querySelector(".overlayed") as HTMLElement;
    // if (!elem) return;
    // if (!overlay) return;
    // if (this.atAnimation) return;
    // this.atAnimation = true;

    // const leftKeyframe = { right: "-225px" };
    // const rightKeyframe = { right: "0px" };
    // this.drawer = !this.drawer;
    // if (this.drawer) {
    //   overlay.style.display = "block";
    //   elem.animate([leftKeyframe, rightKeyframe], 300);
    //   elem.style.right = "0px";
    //   this.atAnimation = false;
    // } else {
    //   elem.animate([rightKeyframe, leftKeyframe], 300);
    //   elem.style.right = "-225px";
    //   this.atAnimation = false;
    //   overlay.style.display = "none";
    // }
  }

  toSlot(id: string) {
    // console.log(`to slot: ${index}`);
    // //@ts-ignore
    // this.$refs.fullpage.api.moveTo(index + 1);
    setTimeout(() => {
      document
        .querySelector(id)
        ?.scrollIntoView({ block: "start", behavior: "smooth" });
      this.toggleDrawer();
    }, 300);
  }

  goto(url: string) {
    window.open(url, "_blank")?.focus();
  }

  wait(timeout: number) {
    return new Promise((resolve) => setTimeout(resolve, timeout));
  }

  async waitForElement(selector: string): Promise<void> {
    if (!document.querySelector(selector)) {
      await this.wait(500);
      return this.waitForElement(selector);
    }
    return;
  }

  async mounted() {
    await this.waitForElement(".overlayed");
    const overlay = document.querySelector(".overlayed") as HTMLElement;
    overlay.classList.add("overlayed-full");
    overlay.style.display = "block";
    await this.waitForElement(".loader");
    const loader = document.querySelector(".loader") as HTMLElement;
    loader.style.display = "block";

    setTimeout(() => {
      loader.style.display = "none";
      overlay.style.display = "none";
    }, 11000);

    // let vh = window.innerHeight * 0.01;
    // document.documentElement.style.setProperty("--vh", `${vh}px`);
    // window.addEventListener("resize", () => {
    //   // We execute the same script as before
    //   let vh = window.innerHeight * 0.01;
    //   document.documentElement.style.setProperty("--vh", `${vh}px`);
    // });
  }

  destroyed() {}
}
