
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class HomeMenu extends Vue {
  @Prop({ type: Object, required: true }) src!: any;
  size = "40px";

  track1 = new Audio(require("@/assets/track1.mp3"));
  track2 = new Audio(require("@/assets/track2.mp3"));
  track3 = new Audio(require("@/assets/track3.mp3"));

  current = this.track1;

  play() {
    this.current.play();
  }
  pause() {
    this.current.pause();
  }

  mounted() {
    this.track1.addEventListener("ended", () => {
      this.current = this.track2;
      this.current.play();
    });

    this.track2.addEventListener("ended", () => {
      this.current = this.track3;
      this.current.play();
    });

    this.track3.addEventListener("ended", () => {
      this.current = this.track1;
      this.current.play();
    });

    // setTimeout(() => {
    //   (document.querySelector("#playButton") as HTMLElement)?.click()
    // }, 6000)

    this.current.play();
  }

  goto(url: string) {
    window.open(url, "_blank")?.focus();
  }
}
